/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import Swal from 'sweetalert2';

function Sidebar() {
  const navigate = useNavigate();

const Logout =()=>{
 Swal.fire({
  title: "Logout SuccesFully",
  icon: "success"
});
  localStorage.clear()
    navigate('/');

}
  const Check = () => {
    const Token = localStorage.getItem("token");
    if (Token) {
      return true;
    } else {
      navigate('/');
    }
  }

  useEffect(() => {
    Check(); // Call the Check function
  }, []); // Pass an empty dependency array
const [activesidebar ,setActiveSidebar] = useState(false)

  
  return (
    <>
  <div className="col-md-3">
    <div onClick={()=>setActiveSidebar(!activesidebar)} style={{
      backgroundColor:'#325096'
    }} className="mob-nav-content-btn color2-bg init-dsmen fl-wrap">
      <i className="fal fa-bars" /> Dashboard menu
    </div>
{
  activesidebar ?   <div className="">
  {/* user-profile-menu*/}
  <div className="user-profile-menu">
    <ul className="no-list-style">
      
      <li>
        <Link  to={'/profile'}>
          <i className="fal fa-user-edit" /> Edit profile
        </Link>
      </li>
      
       
    </ul>
  </div>

  <div className="user-profile-menu">
    <ul className="no-list-style">
      <li>
        <Link  to="/my_listing">
          <i className="fal fa-th-list" /> My listings
        </Link> 
      </li>
      <li>
        <Link  to={'/booking'}>
          {" "}
          <i className="fal fa-calendar-check" /> Bookings{" "}
         
        </Link>
      </li>
     
       <li>
       <Link  to="/subscribe">
          <i className="fal fa-th-list" />Subscribe Your List
        </Link> 
       </li>
        <li>
            <Link to={"/messages"}>
              <i className="fal fa-envelope" />
              Message
            </Link>
          </li>
      <li>
        <Link 
          to="/addlisting"
          className="user-profile-act"
        >
          <i className="fal fa-file-plus" /> Add New
        </Link> 
      </li>
      
    </ul>
  </div>
  <button style={{
              
              marginBottom: '34px'
          
                      }} className="logout_btn color2-bg" onClick={Logout}>
    Log Out <i className="fas fa-sign-out" />
  </button>

</div> : null
}
   
    
    <div className="clearfix" />
    <div className="fixed-bar fl-wrap" id="dash_menu">
      <div className="user-profile-menu-wrap fl-wrap block_box">
        {/* user-profile-menu*/}
        <div className="user-profile-menu">
       
          <ul className="no-list-style">
            {/* <li>
              <Link  to="dashboard.html">
                <i className="fal fa-chart-line" />
                Dashboard
              </Link> 
            </li> */}
            {/* <li>
              <Link  to="dashboard-feed.html">
                <i className="fal fa-rss" />
                Your Feed <span>7</span>
              </Link> 
            </li> */}
            <li>
              <Link  to={'/profile'}>
                <i className="fal fa-user-edit" /> Edit profile
              </Link>
            </li>
            {/* <li>
              <Link  to="dashboard-messages.html">
                <i className="fal fa-envelope" /> Messages <span>3</span>
              </Link> 
            </li> */}
            {/* <li>
              <Link  to={'/changepassword'}>
                <i className="fal fa-key" />
                Change Password
              </Link>
            </li> */}
            {/* <li>
              <Link  to="#" className="submenu-link">
                <i className="fal fa-plus" />
                Submenu
              </Link> 
              <ul className="no-list-style">
                <li>
                  <Link  to="#">
                    <i className="fal fa-th-list" /> Submenu 2{" "}
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    {" "}
                    <i className="fal fa-calendar-check" /> Submenu 2
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="fal fa-comments-alt" />
                    Submenu 2
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="fal fa-file-plus" /> Submenu 2
                  </Link> 
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
        {/* user-profile-menu end*/}
        {/* user-profile-menu*/}
        <div className="user-profile-menu">
          <ul className="no-list-style">
            <li>
              <Link  to="/my_listing">
                <i className="fal fa-th-list" /> My listings
              </Link> 
            </li>
            <li>
              <Link  to={'/booking'}>
                {" "}
                <i className="fal fa-calendar-check" /> Bookings{" "}
               
              </Link>
            </li>
            {/* <li>
              <Link  to="dashboard-review.html">
                <i className="fal fa-comments-alt" /> Reviews{" "}
              </Link> 
            </li> */}
             <li>
             <Link  to="/subscribe">
                <i className="fal fa-th-list" />Subscribe Your List
              </Link> 
             </li>
              <li>
                  <Link to={"/messages"}>
                    <i className="fal fa-envelope" />
                    Message
                  </Link>
                </li>
            <li>
              <Link 
                to="/addlisting"
                className="user-profile-act"
              >
                <i className="fal fa-file-plus" /> Add New
              </Link> 
            </li>

            {/* <li>
              <Link  to="#" className="submenu-link">
                <i className="fal fa-plus" />
                Submenu
              </Link> 
              <ul className="no-list-style">
                <li>
                  <Link  to="#">
                    <i className="fal fa-th-list" /> Submenu 2{" "}
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    {" "}
                    <i className="fal fa-calendar-check" /> Submenu 2
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="fal fa-comments-alt" />
                    Submenu 2
                  </Link> 
                </li>
                <li>
                  <Link  to="#">
                    <i className="fal fa-file-plus" /> Submenu 2
                  </Link> 
                </li>
              </ul>
            </li> */}
            {/* <li>
              <Link  to={'/paypal_plan'}>
                <i className="fal fa-user-edit" />Paypal Plan
              </Link>
            </li> */}
          </ul>
        </div>
        {/* user-profile-menu end*/}
        <button className="logout_btn color2-bg" onClick={Logout}>
          Log Out <i className="fas fa-sign-out" />
        </button>
      </div>
    </div>
  
    <div className="clearfix" />
  </div>
  </>

  )
}

export default Sidebar