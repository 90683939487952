/* eslint-disable */
import React, { useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import BannerDashboard from "../Components/BannerDashboard";
import Layout from "../Components/layout/Layout";
import { useNavigate } from "react-router-dom";
import ListenRight from "../Components/ListenRight";

function AddListing() {
  const navigate = useNavigate();

  const Check = () => {
    const Token = localStorage.getItem("token");
    if (Token) {
      return true;
    } else {
      navigate('/');
    }
  }

  useEffect(() => {
    Check(); // Call the Check function
  }, []); // Pass an empty dependency array

  return (
    <Layout>
      <div id="wrapper">
        {/* content*/}
        <div className="content">
          {/*  section  */}

          <BannerDashboard />
          {/*  section  end*/}
          {/*  section  */}
          <section className="gray-bg main-dashboard-sec" id="sec1">
            <div className="container">
              {/*  dashboard-menu*/}
              <Sidebar />
              {/* dashboard-menu  end*/}
              {/* dashboard content*/}
       <ListenRight/>
              {/* dashboard content end*/}
            </div>
          </section>
          {/*  section  end*/}
          <div className="limit-box fl-wrap" />
        </div>
        {/*content end*/}
      </div>
    </Layout>
  );
}

export default AddListing;
