import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
const ContactUs = () => {
  const containerStyle = {
    minHeight: '100vh',
    backgroundColor: '#f3f4f6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  };

  const formWrapperStyle = {
    width: '100%',
    maxWidth: '700px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    padding: '30px',
  };

  const titleStyle = {
    fontSize: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  };

  const textStyle = {
    textAlign: 'center',
    color: '#666',
    marginBottom: '30px',
  };

  const inputGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  };

  const inputStyle = {
    padding: '12px',
    borderRadius: '6px',
    border: '1px solid #ccc',
    fontSize: '16px',
    outline: 'none',
    transition: 'border-color 0.3s',
  };

  const textAreaStyle = {
    ...inputStyle,
    height: '120px',
    resize: 'none',
  };

  const buttonStyle = {
    padding: '12px 20px',
    backgroundColor: '#1d4ed8',
    color: '#fff',
    borderRadius: '6px',
    fontSize: '16px',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    transition: 'background-color 0.3s',
  };

  const navigation = useNavigate()

  // Yup validation schema
  const validationSchema = Yup.object({
    fullName: Yup.string()
      .min(3, 'Name must be at least 3 characters long')
      .required('Full Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    message: Yup.string()
      .min(10, 'Message must be at least 10 characters long')
      .required('Message is required'),
  });

  // Form submission handler
  const handleSubmit = (values, { setSubmitting }) => {
    setTimeout(() => {
      console.log(values);
      Swal.fire({
        // title: "Good job!",
        text: "Message sent successfully",
        icon: "success"
      });
      navigation('/')
    
      setSubmitting(false);
    }, 500);
  };

  return (
    <div style={containerStyle}>
      <div style={formWrapperStyle}>
        <h2 style={titleStyle}>Contact Us</h2>
        <p style={textStyle}>
        If you have any questions, feedback, or inquiries, feel free to reach out to us:
        </p>
        <Formik
          initialValues={{ fullName: '', email: '', message: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                <div style={inputGroupStyle}>
                  <label style={{ marginBottom: '5px', fontWeight: '500', textAlign: 'start' }}>
                    Full Name
                  </label>
                  <Field
                    type="text"
                    name="fullName"
                    style={inputStyle}
                    placeholder="John Doe"
                  />
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    style={{ color: 'red', fontSize: '14px' ,textAlign:'start' }}
                  />
                </div>
                <div style={inputGroupStyle}>
                  <label style={{ marginBottom: '5px', fontWeight: '500', textAlign: 'start' }}>
                    Email Address
                  </label>
                  <Field
                    type="email"
                    name="email"
                    style={inputStyle}
                    placeholder="you@example.com"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    style={{ color: 'red', fontSize: '14px',textAlign:'start'  }}
                  />
                </div>
              </div>

              <div style={inputGroupStyle}>
                <label style={{ marginBottom: '5px', fontWeight: '500', textAlign: 'start' }}>
                  Message
                </label>
                <Field
                  as="textarea"
                  name="message"
                  style={textAreaStyle}
                  placeholder="Write your message here..."
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  style={{ color: 'red', fontSize: '14px',textAlign:'start' }}
                />
              </div>

              <button
                type="submit"
                style={buttonStyle}
                disabled={isSubmitting}
                onMouseOver={(e) => (e.target.style.backgroundColor = '#2563eb')}
                onMouseOut={(e) => (e.target.style.backgroundColor = '#1d4ed8')}
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ContactUs;
