import React from 'react'
import Sidebar from '../Components/Sidebar'
import BannerDashboard from '../Components/BannerDashboard'
import ProfileRight from '../Components/ProfileRight'
import Layout from '../Components/layout/Layout'

function Profile() {
  
  return (
    <Layout>


    <div id="wrapper">
    {/* content*/}
    <div className="content">
      {/*  section  */}
    <BannerDashboard/>
      {/*  section  end*/}
      {/*  section  */}
      <section className="gray-bg main-dashboard-sec" id="sec1">
        <div className="container">
          {/*  dashboard-menu*/}
      <Sidebar/>
          {/* dashboard-menu  end*/}
          {/* dashboard content*/}
        <ProfileRight/>
          {/* dashboard content end*/}
        </div>
      </section>
      {/*  section  end*/}
      <div className="limit-box fl-wrap" />
    </div>
    {/*content end*/}
  </div>
  </Layout>
  )
}

export default Profile