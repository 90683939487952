
const PrivacyPolicy = () => {
  return (
    <div style={{minHeight:'100vh',padding:'15px'}}>
        <div style={{paddingTop:'10px'}}>
        <h1>Privacy Policy</h1>

        
        
        <div >
            <h2 style={{paddingTop:'10px',textAlign:'start'}}>
            Introduction
            </h2>
        </div>
        <div style={{paddingTop:'20px',fontWeight:"bold",textAlign:'start'}}>We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you use our mobile application.</div>
        </div>
        <div>
            <p style={{textAlign:'start',fontWeight:'bolder',marginTop:'10px'}}>
            1. Information We Collect
            </p>
            <p style={{textAlign:'start',marginTop:'10px'}}>

            Personal Information: We may collect personal details such as your name, email address, and phone number if you create an account.
            Usage Data: We collect data related to your interactions with the app, such as the pages you view, the chalets you explore, and your search preferences.
            </p>
        </div>

        <div>
            <p style={{textAlign:'start',fontWeight:'bolder',marginTop:'10px'}}>
            2. How We Use Your Information
            </p>
            <p style={{textAlign:'start',marginTop:'10px'}}>
            To provide and maintain the functionality of the app. <br/>
            To improve user experience and app performance. <br/>
            To communicate with you about updates, offers, and support.
            </p>
        </div>

        <div>
            <p style={{textAlign:'start',fontWeight:'bolder',marginTop:'10px'}}>
            3. Sharing Your Information
            </p>
            <p style={{textAlign:'start',marginTop:'10px'}}>
            Sharing Your Information We do not sell or rent your personal information. We may share data with trusted third-party service providers for analytics or to enhance app functionality.
            </p>
        </div>


        <div>
            <p style={{textAlign:'start',fontWeight:'bolder',marginTop:'10px'}}>
            4. Security We take appropriate
            </p>
            <p style={{textAlign:'start',marginTop:'10px'}}>
            Security We take appropriate security measures to protect your data. However, no method of transmission over the internet is entirely secure.
            </p>
        </div>

        <div>
            <p style={{textAlign:'start',fontWeight:'bolder',marginTop:'10px'}}>
            5. Your Rights You can request access
            </p>
            <p style={{textAlign:'start',marginTop:'10px'}}>
            Your Rights You can request access, correction, or deletion of your personal information at any time by contacting us at yallachalet@gmail.com.
            </p>
        </div>

        <div>
            <p style={{textAlign:'start',fontWeight:'bolder',marginTop:'10px'}}>
            6. Changes to This Policy 
            </p>
            <p style={{textAlign:'start',marginTop:'10px'}}>
            Changes to This Policy We reserve the right to update this Privacy Policy. Any changes will be posted within the app.
            </p>
        </div>

        <div>
            <p style={{textAlign:'start',fontWeight:'bolder',marginTop:'10px'}}>
            6. Changes to This Policy 
            </p>
            <p style={{textAlign:'start',marginTop:'10px'}}>
            Changes to This Policy We reserve the right to update this Privacy Policy. Any changes will be posted within the app.
            </p>
        </div>

    </div>
  )
}

export default PrivacyPolicy
