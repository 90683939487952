import React from 'react'
import { Link } from 'react-router-dom'

function BannerDashboard() {
  const data = JSON.parse(localStorage.getItem("data")) 
  console.log(data,"data");
  return (
    <section
      className="parallax-section dashboard-header-sec gradient-bg"
      data-scrollax-parent="true"
    >
      <div className="container">
        <div className="dashboard-breadcrumbs breadcrumbs">
          <Link  to="/dashboard">Dasboard</Link> 
          
        </div>
        {/*Tariff Plan menu*/}
   
        {/*Tariff Plan menu end*/}
        <div className="dashboard-header_conatiner fl-wrap dashboard-header_title">
          <h1>
            Welcome : <span>{data?.first_name}</span>
          </h1>
        </div>
      </div>
      <div className="clearfix" />
      <div className="dashboard-header fl-wrap">
        <div className="container">
          <div className="dashboard-header_conatiner fl-wrap">
            <div className="dashboard-header-avatar">
              <img src={data?.image} alt="" />
              <Link 
                to="/profile"
                className="color-bg edit-prof_btn"
              >
                <i className="fal fa-edit" />
              </Link> 
            </div>
            <div className="dashboard-header-stats-wrap">
              <div className="dashboard-header-stats">
                <div className="swiper-container">
                  <div className="swiper-wrapper">
                    {/*  dashboard-header-stats-item */}
                    {/* <Link to={"/addlisting"}>

                    </Link> */}

            
                    {/*  dashboard-header-stats-item end */}
                   
                    {/*  dashboard-header-stats-item end */}
                  </div>
                </div>
              </div>
              {/*  dashboard-header-stats  end */}
         
            </div>
            {/*  dashboard-header-stats-wrap end */}
            
          </div>
        </div>
      </div>
      <div className="gradient-bg-figure" style={{ right: "-30px", top: 10 }} />
      <div
        className="gradient-bg-figure"
        style={{ left: "-20px", bottom: 30 }}
      />
      <div
        className="circle-wrap"
        style={{ left: 120, bottom: 120 }}
        data-scrollax="properties: { translateY: '-200px' }"
      >
        <div className="circle_bg-bal circle_bg-bal_small" />
      </div>
      <div
        className="circle-wrap"
        style={{ right: 420, bottom: "-70px" }}
        data-scrollax="properties: { translateY: '150px' }"
      >
        <div className="circle_bg-bal circle_bg-bal_big" />
      </div>
      <div
        className="circle-wrap"
        style={{ left: 420, top: "-70px" }}
        data-scrollax="properties: { translateY: '100px' }"
      >
        <div className="circle_bg-bal circle_bg-bal_big" />
      </div>
      <div className="circle-wrap" style={{ left: "40%", bottom: "-70px" }}>
        <div className="circle_bg-bal circle_bg-bal_middle" />
      </div>
      <div className="circle-wrap" style={{ right: "40%", top: "-10px" }}>
        <div
          className="circle_bg-bal circle_bg-bal_versmall"
          data-scrollax="properties: { translateY: '-350px' }"
        />
      </div>
      <div className="circle-wrap" style={{ right: "55%", top: 90 }}>
        <div
          className="circle_bg-bal circle_bg-bal_versmall"
          data-scrollax="properties: { translateY: '-350px' }"
        />
      </div>
    </section>
  )
}

export default BannerDashboard