import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

// import required modules
import { Pagination, FreeMode } from "swiper/modules";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <div className="content">
  
      {/*  section  */}
      <section
        className="parallax-section single-par"
        data-scrollax-parent="true"
      >
        <div
          className="bg par-elem "
          data-bg="images/bg/30.jpg"
          data-scrollax="properties: { translateY: '30%' }"
          style={{
            backgroundImage: 'url("/images/bg/30.jpg")',
            transform: "translateZ(0px) translateY(-3.24763%)",
          }}
        />
        <div className="" />
        <div className="container">
          <div className="section-title center-align big-title">
            <h2>
              <span>About Our Company</span>
            </h2>
            <span className="section-separator" />
            <div className="breadcrumbs fl-wrap">
              <Link to="/">Home</Link>
            
              <span>About us</span>
            </div>
          </div>
        </div>
        <div className="header-sec-link">
          <Link to="#sec1" className="custom-scroll-link">
            <i className="fal fa-angle-double-down" />
          </Link>
        </div>
      </section>
      {/*  section  end*/}
      <section id="sec1" data-scrollax-parent="true">
        <div className="container">
          <div className="section-title">
            <h2> All-inclusive, aviation-centric lodging.</h2>
            <div className="section-subtitle">who we are</div>
            <span className="section-separator" />
            <p>Why waste a good hangar home or airpark home?</p>
          </div>
          {/*about-wrap */}
          <div className="about-wrap">
            <div className="row">
              <div className="col-md-6">
                <div className="ab_text">
                  <div className="ab_text-title fl-wrap">
                    <h3>GUESTS</h3>

                    <span className="section-separator fl-sec-sep" />
                  </div>
                  <p>
                    Are you looking for a fun place to fly where you can safely
                    hangar or tie down your aircraft? Maybe you are looking for
                    a fun getaway or maybe you are on a business trip and a
                    Fly-Inn would be much more convenient than a hotel. Do you
                    need to rent a car or a different airplane when you land?
                    Are you craving adventure?
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ab_text">
                  <div className="ab_text-title fl-wrap">
                    <h3>HOSTS</h3>

                    <span className="section-separator fl-sec-sep" />
                  </div>
                  <p>
                    Are you looking into renting out your home that has access
                    to a runway or landing strip? Maybe you have a spacious
                    hangar home with a spare room. Maybe your home is
                    conveniently by an airport. Are you wishing you could rent
                    it and share it with your fellow pilots who have the same
                    sense of respect and responsibility you do?
                  </p>
                </div>
              </div>
            </div>
            <div className="section-title">
              <span className="section-separator" />
              <p style={{ textAlign: "center" }}>
                Look no further…Pilot-Inn has you covered.
              </p>
            </div>
          </div>
          {/* about-wrap end  */}
        </div>
      </section>
      {/*section end*/}
      {/*section  */}

      {/*section end*/}
      {/*section  */}

      {/*section end*/}
      {/*section */}
      <section id="sec3">
        <div className="container">
          <div className="section-title">
            <h2>Our Values.</h2>
            <div className="section-subtitle">the crew</div>
          </div>
          <div className="about-wrap team-box2 fl-wrap">
            {/* team-item */}
            <div className="team-box">
              <div className="team-photo">
                <img src="images/bg/2.jpg" alt="" className="respimg" />
              </div>
              <div className="team-info fl-wrap">
                <h3>Teamwork</h3>
                <p>
                  It takes all three of us to provide a great experience. <br />
                  Pilot-Inn is committed to timely and effective communication
                  and making sure you are completely satisfied with your
                  experience. <br />
                  Hosts are committed to your satisfaction, by contract. <br />
                  Guests are also committed by contract to taking care of the
                  space entrusted to them and to being safe.
                </p>
              </div>
            </div>
            {/* team-item  end*/}
            {/* team-item */}
            <div className="team-box">
              <div className="team-photo">
                <img src="images/bg/4value.jpg" alt="" className="respimg" />
              </div>
              <div className="team-info fl-wrap">
                <h3>Integrity and respect</h3>
                <p>
                  The very reason Pilot-Inn is so successful is that as a
                  community we know we can trust each other’s level of respect
                  and integrity. We are a family of like-minded, respectful
                  individuals and we understand each other. We take pride in
                  treating each other according to the Golden Rule.
                </p>
              </div>
            </div>
            <div className="team-box">
              <div className="team-photo">
                <img src="images/bg/3value.jpg" alt="" className="respimg" />
              </div>
              <div className="team-info fl-wrap">
                <h3>Quality and innovation</h3>
                <p>
                  As a team, we are all committed to the highest quality rentals
                  and service. We love coming up with new ways to make your
                  stays better and better over time.
                </p>
              </div>
            </div>
            {/* team-item end  */}
            {/* team-item */}

            {/* team-item end  */}
          </div>
        </div>
        <div className="waveWrapper waveAnimation">
          <div className="waveWrapperInner bgMiddle">
            <div
              className="wave-bg-anim waveMiddle"
              style={{ backgroundImage: 'url("images/wave-top.png")' }}
            />
          </div>
          <div className="waveWrapperInner bgBottom">
            <div
              className="wave-bg-anim waveBottom"
              style={{ backgroundImage: 'url("images/wave-top.png")' }}
            />
          </div>
        </div>
      </section>
      {/*section end*/}
      {/*section  */}
      <section id="sec2" className="gray-bg">
        <div className="container">
          <div className="clients-carousel-wrap fl-wrap">
            <div className="clients-carousel">
              <Swiper
                slidesPerView={4}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    style={{ width: 268, marginRight: 10 }}
                  >
                    <Link to="#" className="client-item">
                      <img src="images/bg/swiper1.jpg" alt="" />
                    </Link>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  {" "}
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-prev"
                    style={{ width: 268, marginRight: 10 }}
                  >
                    <Link to="#" className="client-item">
                      <img src="images/bg/swiper2.jpg" alt="" />
                    </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="swiper-slide swiper-slide-active"
                    style={{ width: 268, marginRight: 10 }}
                  >
                    <Link to="#" className="client-item">
                      <img src="images/bg/swiper3.jpg" alt="" />
                    </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="swiper-slide swiper-slide-next"
                    style={{ width: 268, marginRight: 10 }}
                  >
                    <Link to="#" className="client-item">
                      <img src="images/bg/s.jpg" alt="" />
                    </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div
                    className="swiper-slide"
                    style={{ width: 268, marginRight: 10 }}
                    data-swiper-slide-index={2}
                  >
                    <Link to="#" className="client-item">
                      <img src="images/bg/swiper5.jpg" alt="" />
                    </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div
                    className="swiper-slide"
                    style={{ width: 268, marginRight: 10 }}
                  >
                    <Link to="#" className="client-item">
                      <img src="images/bg/swiper6.jpg" alt="" />
                    </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    style={{ width: 268, marginRight: 10 }}
                  >
                    <Link to="#" className="client-item">
                      <img src="images/bg/swiper7.jpg" alt="" />
                    </Link>
                  </div>
                </SwiperSlide>
              </Swiper>

              {/*client-item end*/}
              {/*client-item*/}

              {/*client-item end*/}
              {/*client-item*/}
            </div>
          </div>
        </div>
      </section>
      {/*section end*/}
      {/*section  */}

      {/*section end*/}
      {/*section  */}

      {/*section end*/}
      {/*section  */}
      <section id="sec5">
        <div className="container">
          <div className="section-title">
            <h2> What's in store.</h2>
            <span className="section-separator" />
          </div>
        </div>
        <div className="clearfix" />
        <div className="testimonilas-carousel-wrap fl-wrap">
          <div className="testimonilas-carousel">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                {" "}
                <div
                  className="swiper-slide swiper-slide-duplicate"
                  style={{ width: "459.5px", marginRight: 20 }}
                >
                  <div className="testi-item fl-wrap">
                    <div className="testi-avatar">
                      <img src="images/avatar/swiper1.jpg" alt="" />
                    </div>
                    <div className="testimonilas-text fl-wrap">
                      <p>
                        Every listing on Pilot-Inn either has a landing strip or
                        helipad conveniently located on the property or is
                        situated within 10 minutes of an airport you are likely
                        to pilot into yourself. Most homes are either hangar
                        homes, airpark homes or have their own private runway.
                        Some homes even offer a water landing!
                      </p>

                      <div className="testimonilas-avatar fl-wrap">
                        <h3>Runway!</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="swiper-slide swiper-slide-duplicate"
                  style={{ width: "459.5px", marginRight: 20 }}
                >
                  <div className="testi-item fl-wrap">
                    <div className="testi-avatar">
                      <img src="images/avatar/swiper2.jpg" alt="" />
                    </div>
                    <div className="testimonilas-text fl-wrap">
                      <p>
                      Whether it be access to a hangar, tie-downs, etc. on the property itself, or whether it be storage services the nearby airport offers, you are sure to have a place where you can securely store your aircraft. 
                      </p>

                      <div className="testimonilas-avatar fl-wrap">
                        <h3>Aircraft Storage!</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="swiper-slide swiper-slide-duplicate"
                  style={{ width: "459.5px", marginRight: 20 }}
                >
                  <div className="testi-item fl-wrap">
                    <div className="testi-avatar">
                      <img src="images/avatar/swiper3.jpg" alt="" />
                    </div>
                    <div className="testimonilas-text fl-wrap">
                      <p>
                      What good is it to land if you can get around once you arrive? our hosts know this and provide either a free loaner or a nice  car to  rent or at least transportation to a nearby car rental.<br/>If the airport is a few minutes from the property, transportation will be made available to you at the airport upon your arrival.
                      </p>

                      <div className="testimonilas-avatar fl-wrap">
                        <h3>Cars!</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="swiper-slide swiper-slide-duplicate"
                  style={{ width: "459.5px", marginRight: 20 }}
                >
                  <div className="testi-item fl-wrap">
                    <div className="testi-avatar">
                      <img src="images/avatar/swiper4.jpg" alt="" />
                    </div>
                    <div className="testimonilas-text fl-wrap">
                      <p>
                      Optional goodies are up to your host and the area. Some hosts even offer other aircraft, ATVs, watercraft, scooters, bikes, dirt bikes and more! Be sure to check the listing to see what is included in your rental.
                      </p>

                      <div className="testimonilas-avatar fl-wrap">
                        <h3>Toys!</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="swiper-slide swiper-slide-duplicate"
                  style={{ width: "459.5px", marginRight: 20 }}
                >
                  <div className="testi-item fl-wrap">
                    <div className="testi-avatar">
                      <img src="images/avatar/swiper5.jpg" alt="" />
                    </div>
                    <div className="testimonilas-text fl-wrap">
                      <p>
                      Some homes are in extremely remote, secluded and private locations and being able to get food might be a challenge. When this is the case, many hosts offer catering or a fully stocked kitchen with your favorite staples and treats!
                      </p>

                      <div className="testimonilas-avatar fl-wrap">
                        <h3>Food!</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="swiper-slide swiper-slide-duplicate"
                  style={{ width: "459.5px", marginRight: 20 }}
                >
                  <div className="testi-item fl-wrap">
                    <div className="testi-avatar">
                      <img src="images/avatar/swiper6.jpg" alt="" />
                    </div>
                    <div className="testimonilas-text fl-wrap">
                      <p>
                      Some hosts are commercial pilots who offer rides for a fee. Others simply offer free rides. They love it because not only do they get to meet their guests, they also get to give them a bird’s-eye view of what fun awaits them when they land!
                      </p>

                      <div className="testimonilas-avatar fl-wrap">
                        <h3>Optional transportation!</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>

            {/*testi-item*/}
          </div>
        </div>
      </section>
      {/*section end*/}
    </div>
  );
};

export default AboutSection;
