import React from "react";

const Loading = () => {
  return (
    <div class="loader-wrap">
      <div class="loader-inner">
        <div class="loader-inner-cirle"></div>
      </div>
    </div>
  );
};

export default Loading;
