import React from 'react'

function ChangePRight() {
  return (
    <div className="col-md-9">
    <div className="dashboard-title   fl-wrap">
      <h3>Change Password</h3>
    </div>
    {/* profile-edit-container*/}
    <div className="profile-edit-container fl-wrap block_box">
      <div className="custom-form">
        <div className="pass-input-wrap fl-wrap">
          <label>Current Password</label>
          <input
            type="password"
            className="pass-input"
            placeholder=""
            defaultValue=""
          />
          <span className="eye">
            <i className="far fa-eye" aria-hidden="true" />{" "}
          </span>
        </div>
        <div className="pass-input-wrap fl-wrap">
          <label>New Password</label>
          <input
            type="password"
            className="pass-input"
            placeholder=""
            defaultValue=""
          />
          <span className="eye">
            <i className="far fa-eye" aria-hidden="true" />{" "}
          </span>
        </div>
        <div className="pass-input-wrap fl-wrap">
          <label>Confirm New Password</label>
          <input
            type="password"
            className="pass-input"
            placeholder=""
            defaultValue=""
          />
          <span className="eye">
            <i className="far fa-eye" aria-hidden="true" />{" "}
          </span>
        </div>
        <button className="btn    color2-bg  float-btn">
          Save Changes
          <i className="fal fa-save" />
        </button>
      </div>
    </div>
    {/* profile-edit-container end*/}
  </div>
  )
}

export default ChangePRight