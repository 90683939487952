// eslint-disable-next-line
// eslint-disable-next-line no-unused-vars
/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import BASE_URL from "./auth/Baseurl";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "./loader/Loading";
import ImageUploading from "react-images-uploading";
import BtnLoader from "./BtnLoader/BtnLoader";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import Swal from "sweetalert2";
import { IoMdLogOut } from "react-icons/io";
import Modal from 'react-modal';
// const containerStyle = {
//   height: "500px",
//   width: "100%",
// };
const customStyles = {

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width:'70%',
    transform: 'translate(-50%, -50%)',
  },
};
function ListenRight() {
  // const [Position, setPosition] = useState({ lat: 0, lng: 0 });
  const [loading, setLoading] = useState(false);
  const [loaders, setLoaders] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    type_of_space: "",
    listing_type: "",
    no_of_bedroom: "",
    no_of_guest: "",
    no_of_bed: "",
    no_of_bathroom: "",
    no_of_room: "",
    pet_allowed: "",
    size: "",
    // instant_booking: 0,
    unit_of_measure: "",
    addi_info: "",
    nightly: "",
    smoking_allowed: "",
    check_in_after: "",
    check_in_before: "",
    per: "",
    house_rule_addi_info: "",
    weekend: "",
    cancellation_policy: "",
    apply_weekend_price: "",
    // min_day_booking: "",
    // max_day_booking: "",
    weekly_seven_plus: "",
    weekly_thirty_plus: "",

    // addi_guest: "",
    // addi_guest_price: "",
    // no_of_addi_guest: "",
    party_allowed: "",
    children_allowed: "",
    clean_fee: "",
    // clean_fee_daily: "",
    // clean_fee_per_stay: "",
    city_fee: "",
    // city_fee_daily: "",
    // abhi
    // city_fee_per_stay: "",
    security_deposit: "",
    tax: "",
    deposit_price: "",
    discount: "",
    amenity: [],
    facility: [],
    address: "",
    apt_suite: "",
    city: "",
    state: "",
    zipcode: "",
    area: "",
    country: "",
    images: "",
    bedroom_name: "",
    bedroom_guest: "",
    bedroom_type: "",
    bedroom_bed: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? (checked ? 1 : 0) : value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };
  // const handleImageChange = (e) => {
  //   const files = e.target.files[0];
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     images: files,
  //   }));
  // };
  // const handleInputChange1 = (e) => {
  //   const { name, value } = e.target;

  //   // Update formData state
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     city_fee_daily: name === "city_fee_daily" && value === "Daily",
  //     city_fee_per_stay: name === "city_fee_per_stay" && value === "Per Stay",
  //   }));
  // };

  // const handleInputChange2 = (e) => {
  //   const { name, value } = e.target;

  //   // Update formData state
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     clean_fee_daily: name === "clean_fee_daily" && value === "Daily",
  //     clean_fee_per_stay: name === "clean_fee_per_stay" && value === "Per Stay",
  //   }));
  // };
  const Navigate = useNavigate();

  // Multiple Input Code Start
  // State for storing multiple inputs
  // State for storing multiple sets of bedroom data
  const [bedrooms, setBedrooms] = useState([
    { name: "", guest: "", bed: "", type: "" },
  ]);

  // Function to add a new set of bedroom data
  const handlePlusClick = () => {
    setBedrooms([...bedrooms, { name: "", guest: "", bed: "", type: "" }]);
  };

  // Function to remove a set of bedroom data
  const handleMinusClick = (index) => {
    if (bedrooms.length > 1) {
      const newBedrooms = [...bedrooms];
      newBedrooms.splice(index, 1);
      setBedrooms(newBedrooms);
    }
  };

  // Function to handle input changes
  const handleInputChangess = (value, index, field) => {
    const newBedrooms = [...bedrooms];
    newBedrooms[index][field] = value;
    setBedrooms(newBedrooms);
  };

  // Multiple Input Code End

  // Image Multiple Code Start
  // const [images, setImages] = useState([]);
  // const maxNumber = 69;
  // const onChange = (imageList, addUpdateIndex) => {
  //   setImages(imageList);
  // };
  // Image Multiple Code End

  // Multiple Input Two Code Start

  const [extraServicePrices, setExtraServicePrices] = useState([
    { name: "", price: "", type: "" },
  ]);

  // const handleInputChangeOne = (index, field, value) => {
  //   const newExtraServicePrices = [...extraServicePrices];
  //   newExtraServicePrices[index][field] = value;
  //   setExtraServicePrices(newExtraServicePrices);
  // };

  // const handlePlusClickOne = () => {
  //   setExtraServicePrices([
  //     ...extraServicePrices,
  //     { name: "", price: "", type: "" },
  //   ]);
  // };

  // const handleMinusClickOne = (index) => {
  //   if (extraServicePrices.length > 1) {
  //     const newExtraServicePrices = [...extraServicePrices];
  //     newExtraServicePrices.splice(index, 1);
  //     setExtraServicePrices(newExtraServicePrices);
  //   }
  // };

  // Multiple Input Two Code End

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaders(true);
    // const valueGet = formData.addi_guest === "1" ? 1 : 0;
    const valueGet1 = formData.children_allowed == "True" ? 1 : 0;
    // const valueGet2 = formData.city_fee_daily == "true" ? 1 : 0;
    // abhi
    // const valueGet3 = formData.city_fee_per_stay == "false" ? 0 : 1;
    // abhi
    // const valueGet4 = formData.clean_fee_daily == "true" ? 1 : 0;
    // const valueGet5 = formData.clean_fee_per_stay == "false" ? 0 : 1;
    const valueGet6 = formData.party_allowed == "No" ? 0 : 1;
    const valueGet7 = formData.smoking_allowed == "No" ? 0 : 1;
    const valueGet8 = formData.pet_allowed == "No" ? 0 : 1;
    const Data = new FormData();
    console.log(Data, "data");
    console.log(JSON.stringify(formData.amenity), ">>>>>>>>>>>>>>");
    console.log(JSON.stringify(formData.facility), ">>>>>>>>>>>>>>");
    Data.append("title", formData.title);
    Data.append("description", formData.description);
    Data.append("type_of_space", formData.type_of_space);
    Data.append("listing_type", formData.listing_type);
    Data.append("no_of_bedroom", Number(formData.no_of_bedroom));
    Data.append("no_of_guest", formData.no_of_guest);
    Data.append("no_of_bed", Number(formData.no_of_bed));
    Data.append("no_of_bathroom", formData.no_of_bathroom);
    Data.append("no_of_room", formData.no_of_room);
    Data.append("size", formData.size);
    // Data.append("instant_booking", formData.instant_booking);
    Data.append("unit_of_measure", formData.unit_of_measure);
    Data.append("addi_info", formData.addi_info);
    Data.append("nightly", formData.nightly);
    Data.append("per", formData.per);
    Data.append("address", query);
    Data.append("apt_suite", formData.apt_suite);
    Data.append("city", formData.city);
    Data.append("state", formData.state);
    Data.append("zipcode", formData.zipcode);
    Data.append("city_fee", formData.city_fee);
    // Data.append("city_fee_daily", valueGet2);
    // abhi
    // Data.append("city_fee_per_stay", valueGet3);
    Data.append("area", formData.area);
    Data.append("smoking_allowed", valueGet7);
    Data.append("pet_allowed", valueGet8);
    Data.append("party_allowed", valueGet6);
    Data.append("children_allowed", valueGet1);
    Data.append("house_rule_addi_info", formData.house_rule_addi_info);
    Data.append("security_deposit", formData.security_deposit);
    Data.append("tax", formData.tax);
    Data.append("deposit_price", formData.deposit_price);
    Data.append("discount", formData.discount);
    Data.append("cancellation_policy", formData.cancellation_policy);
    // Data.append("min_day_booking", formData.min_day_booking);
    // Data.append("max_day_booking", formData.max_day_booking);
    Data.append("check_in_before", formData.check_in_before);
    Data.append("check_in_after", formData.check_in_after);
    Data.append("country", formData.country);
    Data.append("weekend", formData.weekend);
    // Data.append("addi_guest_price", formData.addi_guest_price);
    // Data.append("no_of_addi_guest", formData.no_of_addi_guest);
    // Data.append("addi_guest", valueGet);
    Data.append("apply_weekend_price", formData.apply_weekend_price);
    Data.append("weekly_seven_plus", formData.weekly_seven_plus);
    Data.append("weekly_thirty_plus", formData.weekly_thirty_plus);
    Data.append("facilities", JSON.stringify(formData.facility));
    Data.append("amenities", JSON.stringify(formData.amenity));
    // Data.append("clean_fee_daily", valueGet4);
    // Data.append("clean_fee_per_stay", valueGet5);
    Data.append("lat", selectedLocation.lat);
    Data.append("lng", selectedLocation.lng);
    bedrooms?.map((item, index) => {
      Data.append(`bedrooms[${index}][name]`, item?.name);
      Data.append(`bedrooms[${index}][no_of_guest]`, item?.guest);
      Data.append(`bedrooms[${index}][no_of_bed]`, item?.bed);
      Data.append(`bedrooms[${index}][bed_type]`, item?.type);
    });

    images?.map((item, index) => {
      Data.append(`images[${index}]`, item?.file);
    });

    // images?.map((item, index) => {
    //   Data.append(`images[${index}]`, item?.file);
    // });

    // extraServicePrices?.map((item, index) => {
    //   Data.append(`extra_service_prices[${index}][name]`, item?.name);
    //   Data.append(`extra_service_prices[${index}][price]`, item?.price);
    //   Data.append(`extra_service_prices[${index}][type]`, item?.type);
    // });

    try {
      const response = await axios.post(`${BASE_URL}/listing`, Data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setLoaders(false);
      window.location.href = response?.data?.url
    
      // Navigate("/");
      // Navigate("/my_listing");
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error?.response?.data?.message,
      });
      setLoaders(false);
    }
  };
  
  useEffect(()=>{
    console.log(location.search,'acjnasjklnascklnas');
  if(
    location.search == "?status=true&message=Listing%20has%20been%20successfully%20added"
  ){
    Swal.fire({
      title: "Listing has been successfully added",
      icon: "success"
    });
Navigate('/addlisting')
  }
  },[])

  const maxNumber = 70; // Maximum number of images allowed
  const [images, setImages] = useState([]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  // const [formData1, setFormData1] = useState({
  //   extra_service_prices: [{ name: "", price: "", type: "" }],
  // });

  const [amenity, setAmenity] = useState([]);
  const GetData = () => {
    axios
      .get(`${BASE_URL}/amenity`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setAmenity(res?.data?.data || []); // Ensure amenity is always an array
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetData();
  }, []);

  const valueGetAmenities = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      amenity: checked
        ? [...prevState.amenity, name]
        : prevState.amenity.filter((item) => item !== name),
    }));
  };
  const [facility, setFacility] = useState([]);
  const GetData1 = () => {
    axios
      .get(`${BASE_URL}/facility`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // console.log(res.data?.data, "values");
        setFacility(res?.data?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    GetData1();
  }, []);
  const valueGetFacilities = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      facility: checked
        ? [...prevState.facility, name] // Add facility if checked
        : prevState.facility.filter((item) => item !== name), // Remove facility if unchecked
    }));
  };

  //google map work

  // const { isLoaded } = useJsApiLoader({
  // id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8",
  //   libraries: ["places"],
  // });
  // const autoCompleteRef = useRef();
  // const inputRef = useRef();

  // useEffect(() => {
  //   console.log("useeffect", +1);
  //   autoCompleteRef.current = new window.google.maps.places.Autocomplete(
  //     inputRef.current
  //   );
  //   autoCompleteRef.current.addListener("place_changed", async function () {
  //     const Place = await autoCompleteRef.current.getPlace();
  //     // console.log(place.geometry.location.lat(),"place");
  //     // console.log(place.geometry.location.lng(),"place");
  //     setPosition({
  //       lat: Place.geometry.location.lat(),
  //       lng: Place.geometry.location.lng(),
  //     });
  //     // setPlaceName(place.formatted_address);
  //     setPlaceName(Place.name);
  //     console.log(Place, "place");
  //   });
  // }, [Position]);

  //google map work

  const [selectedLocation, setSelectedLocation] = useState({
    lat: 28.7041,
    lng: 77.1025,
  });
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  let autoComplete;

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        // types: ["(cities)"],
      }
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log({ query });
    const latLng = {
      lat: addressObject.geometry.location.lat(),
      lng: addressObject.geometry.location.lng(),
    };
    console.log({ latLng });
    setSelectedLocation(latLng);
  };

  useEffect(() => {
    handleScriptLoad(setQuery, autoCompleteRef);
  }, [autoCompleteRef, setQuery]);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  console.log(JSON.stringify(query), "values>>>>>>");

  const [subscribetion, setsubscribetion] = useState(null);
  const [loader , setLoader] = useState(false)


// const subscribeCheck = async()=>{

//   axios.get(`${BASE_URL}/subscribe-check`, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   })
//   .then((res) => {
//     console.log(res.data,'subscribeCheck');
//     setsubscribetion(res?.data?.status)
//   })
//   .catch((err) => {
//     console.log(err?.response?.data?.status,'subscribeCheck');
//     setsubscribetion(err.response.data.status)
//   });

// };
// useEffect(()=>{
//   subscribeCheck()
//   if(location.search == '?status=true&message=Successfully%20Subscribe'){
//     Swal.fire({
//       title: "Your Subscription is Activated",
//       icon: "success"
//     });
//   }
// },[])

// const SubscribeMonthlyDeal =()=>{

//   !subscribetion ? (

//     axios.get(`${BASE_URL}/subscribe`, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     }).then((res) => {
//       console.log(res?.data?.url,'responce')
//       window.location.href = res?.data?.url
//       setLoader(true)
//     }).catch((err) => {
//       // setIsOpen(err?.response?.data.status)
//       console.log(err,'responce')
//       setLoader(true)
//     })

//   ) : null
// }
const [afterFiveMinutes, setafterFiveMinutes] = useState(false);
const navigation = useNavigate()
const [modalIsOpen, setIsOpen] = useState(false);

const checking = async()=>{

  axios.get(`${BASE_URL}/connected-account-status-check`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
  .then((res) => {
    
    setIsOpen(res?.data?.status)
  setafterFiveMinutes(res)

    // setFacility(res.data?.data || []);
  })
  .catch((err) => {
    setIsOpen(err?.data?.status)
    err ? setafterFiveMinutes(err) : null
  

  });

};

useEffect(()=>{
  checking()
  if(location.search == '?status=true&message=Your%20Connected%20Create%20Successfully'){
    Swal.fire({
      title: "Account Connected",
      icon: "success"
    });
    navigation('/dashboard')
  }
},[])

console.log(setIsOpen,'setIsOpen');


const ActivateAccount =()=>{

  !modalIsOpen ? (

    axios.get(`${BASE_URL}/connected-account`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      
      // console.log(res?.data?.url,'responcesss')
      window.location.href = res?.data?.url
      setLoader(false)

    }).catch((err) => {
      // setIsOpen(err?.response?.data.status)
      // console.log(err?.data?.url,'responcesss')
      window.location.href = err?.data?.url
      setLoader(false)
    })

  ) : null
}
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
         
    {
      
    afterFiveMinutes ? 

<>
{!modalIsOpen ? <div>
       <h1 style={{
        fontSize:'12px',
        color:'red'
       }}>Please connect your account before proceeding. Your listings will not be displayed until your account is connected.</h1>

       <div>

        <button disabled={loader} onClick={()=>(ActivateAccount(),setLoader(true))} style={{
          cursor:'pointer',
          marginTop:'10px',
          padding:'8px',
          backgroundColor:'#6DBBFD',
                    color:'white',
                    width:'30%',
                    borderRadius:'5px',
                    border:'none'
        }}>
          {loader ? 'Loading......' : "Connect Your Paypal Account" }
          
        </button>

       </div>

</div> : null}

          <form onSubmit={handleSubmit}>
           <div className="col-md-9">
            <div className="dashboard-title   fl-wrap">
              <h3>Add Listing</h3>
            </div>
            <div className="profile-edit-container fl-wrap  block_box">
              <div className="custom-form add_room-item-wrap">
                <div className="add_room-container fl-wrap">
                  {/*add_room-item end  */}
                  <div className="add_room-item fl-wrap">
                    <span
                      className="remove-rp tolt"
                      data-microtip-position="left"
                      data-tooltip="Remove"
                    >
                      <i className="fal fa-times-circle" />
                    </span>
                    <div className="row">
                      <h3
                        style={{
                          textAlign: "start",
                          margin: "15px",
                          color: "#878C9F",
                        }}
                      >
                        What type of space would you like to list?*
                      </h3>
                      <div className="col-md-4">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="entirePlace"
                                  type="radio"
                                  name="type_of_space"
                                  value="Entire Place"
                                  checked={
                                    formData.type_of_space === "Entire Place"
                                  }
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="entirePlace">
                                  Entire Place
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="privateRoom"
                                  type="radio"
                                  name="type_of_space"
                                  value="Private Room"
                                  checked={
                                    formData.type_of_space === "Private Room"
                                  }
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="privateRoom">
                                  Private Room
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <input
                                id="sharedRoom"
                                type="radio"
                                name="type_of_space"
                                value="Shared Room"
                                checked={
                                  formData.type_of_space === "Shared Room"
                                }
                                onChange={handleInputChange}
                              />
                              <label htmlFor="sharedRoom">Shared Room</label>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>
                          Title
                          <i className="fal fa-briefcase" />
                        </label>
                        <input
                          type="text"
                          placeholder="Title"
                          name="title"
                          defaultValue=""
                          value={formData.title}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Description
                          <i className="fal fa-info-circle" />
                        </label>
                        <input
                          type="text"
                          placeholder="Description"
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                          defaultValue=""
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Type of listing</label>
                        <div className="listsearch-input-item">
                          <select
                            value={formData.listing_type}
                            name="listing_type"
                            onChange={handleInputChange}
                            data-placeholder="Apartments"
                            className="nice-select chosen-select no-search-select"
                            // style={{ display: "none" }}
                          >
                            <option>All Categories</option>
                            <option className="list">Shops</option>
                            <option>Hotels</option>
                            <option>Restaurants</option>
                            <option>Fitness</option>
                            <option>Events</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label>
                          Number of Bedrooms (N)
                          <i className="fal fa-bed" />
                        </label>
                        <input
                          type="number"
                          placeholder="Number of Bedrooms"
                          name="no_of_bedroom"
                          value={formData.no_of_bedroom}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Capacity
                          <i className="fal fa-user-friends" />
                        </label>
                        <input
                          type="number"
                          placeholder="Capacity"
                          name="no_of_guest"
                          value={formData.no_of_guest}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Number of Beds (N)
                          <i className="fal fa-bed" />
                        </label>
                        <input
                          id="no_of_bed"
                          type="number"
                          placeholder="Number of Beds"
                          name="no_of_bed"
                          value={formData.no_of_bed}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Number of Bathrooms (N)
                          <i className="fal fa-bath" />
                        </label>
                        <input
                          type="number"
                          placeholder="Number of Bathrooms"
                          name="no_of_bathroom"
                          value={formData.no_of_bathroom}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Number of Rooms (N)
                          <i className="fal fa-door-open" />
                        </label>
                        <input
                          type="number"
                          placeholder="Number of Rooms"
                          name="no_of_room"
                          value={formData.no_of_room}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Size (N)
                          <i className="fal fa-ruler" />
                        </label>
                        <input
                          type="number"
                          placeholder="Size"
                          name="size"
                          value={formData.size}
                          onChange={handleInputChange}
                        />
                      </div>
                      {/* hogya */}
                      {/* <div className="col-md-12">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="check-aaa5"
                                  type="checkbox"
                                  name="instant_booking"
                                  checked={formData.instant_booking === 1}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="check-aaa5">
                                  Allow instant booking for this space.
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> */}
                      
                      <div className="col-md-4">
                        <label>
                          Unit of Measure (N)
                          <i className="fal fa-ruler" />
                        </label>
                        <input
                          type="number"
                          placeholder="Unit of Measure"
                          defaultValue=""
                          name="unit_of_measure"
                          value={formData.unit_of_measure}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Additional Information
                          <i className="fal fa-info-circle" />
                        </label>
                        <input
                          type="text"
                          placeholder="Additional Information"
                          defaultValue=""
                          name="addi_info"
                          value={formData.addi_info}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-md-4">
                        <label>
                          Nightly Price (N)
                          <i className="fal fa-moon-stars" />
                        </label>
                        <input
                          type="number"
                          placeholder="Nightly Price"
                          defaultValue=""
                          name="nightly"
                          value={formData.nightly}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Per
                          <i className="fal fa-calendar-alt" />
                        </label>
                        <input
                          type="text"
                          placeholder="Per"
                          defaultValue=""
                          name="per"
                          value={formData.per}
                          onChange={handleInputChange}
                        />
                      </div>


                      <div className="col-md-4">
                        <label>Apply Weekend Price </label>
                        <div className="listsearch-input-item">
                          <select
                            value={formData.apply_weekend_price}
                            onChange={handleInputChange}
                            name="apply_weekend_price"
                            // data-placeholder="Apartments"
                            className="nice-select chosen-select no-search-select"
                            // style={{ display: "none" }}
                          >
                            <option value={"Saturday and Sunday"}>
                              Saturday and Sunday
                            </option>
                            <option value={"Monday and Tuesday"}>
                              Monday and Tuesday
                            </option>
                            <option value={"Wednesday and Thursday"}>
                              Wednesday and Thursday
                            </option>
                            <option value={"Thursday and Friday"}>
                               Thursday and Friday 
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label>
                          Weekend (N)
                          <i className="fal fa-check-square" />
                        </label>
                        <input
                          type="number"
                          placeholder="Apply Weekend Price"
                          defaultValue=""
                          name="weekend"
                          value={formData.weekend}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>
                          Weekly (7+ Days) Price (N)
                          <i className="fal fa-calendar-week" />
                        </label>
                        <input
                          type="number"
                          placeholder="Weekly (7+ Days) Price"
                          name="weekly_seven_plus"
                          value={formData.weekly_seven_plus}
                          onChange={handleInputChange}
                          defaultValue=""
                        />
                      </div>
                      <div className="col-md-6">
                        <label>
                          Weekly (30+ Days) Price (N)
                          <i className="fal fa-calendar-alt" />
                        </label>
                        <input
                          type="number"
                          placeholder="Weekly (30+ Days) Price"
                          name="weekly_thirty_plus"
                          value={formData.weekly_thirty_plus}
                          onChange={handleInputChange}
                          defaultValue=""
                        />
                      </div>

                      {/* Multiple Input Code Start */}
                      {/* <div>
                        {extraServicePrices.map((service, index) => (
                          <div className="row" key={index}>
                            <div className="col-md-4">
                              <label>Extra Service Prices Name</label>
                              <input
                                type="text"
                                placeholder="Extra Service Name"
                                value={service.name}
                                onChange={(e) =>
                                  handleInputChangeOne(
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label>Extra Service Prices Price (N)</label>
                              <input
                                type="number"
                                placeholder="Extra Service Prices"
                                value={service.price}
                                onChange={(e) =>
                                  handleInputChangeOne(
                                    index,
                                    "price",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label>Extra Service Prices Type</label>
                              <select
                                data-placeholder="Apartments"
                                className="nice-select chosen-select no-search-select"
                                value={service.type}
                                onChange={(e) =>
                                  handleInputChangeOne(
                                    index,
                                    "type",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">All Categories</option>
                                <option value="Shops">Shops</option>
                                <option value="Hotels">Hotels</option>
                                <option value="Restaurants">Restaurants</option>
                                <option value="Fitness">Fitness</option>
                                <option value="Events">Events</option>
                              </select>
                            </div>
                            {extraServicePrices.length > 1 && (
                              <div className="col-md-12">
                                <button
                                  className="btn btn-danger"
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    marginBottom: "20px",
                                    marginTop: "0px",
                                  }}
                                  onClick={() => handleMinusClickOne(index)}
                                >
                                  Remove Service
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                        <div
                          className="col-md-12"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginBottom: "30px",
                            marginRight: "20px",
                          }}
                        >
                          <div
                            className="btn btn-primary cursor-pointer"
                            onClick={handlePlusClickOne}
                            style={{
                              marginRight: "20px",
                            }}
                          >
                            Add Service
                            <i className="fas fa-sign-out" />
                          </div>
                        </div>
                      </div> */}
                      {/* Multiple Input Code End */}

                      {/* hogya */}
                      {/* <div className="col-md-12 d- flex justify-content-spacebetween">
                        <label style={{ paddingRight: "10px" }}>
                          Additional Guest
                        </label>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="addi_guest"
                                    type="radio"
                                    name="addi_guest"
                                    value={1}
                                    checked={formData.addi_guest == 1}
                                    onChange={handleInputChange}
                                  />
                                  <label htmlFor="addi_guest">Yes</label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="addi_guest"
                                    type="radio"
                                    name="addi_guest"
                                    value="No"
                                    checked={formData.addi_guest === "No"}
                                    onChange={handleInputChange}
                                  />
                                  <label htmlFor="addi_guest">No</label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}


                      {/* <div className="col-md-8">
                        <label>
                          Additional Guest Price (N)
                          <i className="fal fa-dollar-sign" />
                        </label>
                        <input
                          type="number"
                          placeholder="Additional Guest Price"
                          name="addi_guest_price"
                          value={formData.addi_guest_price}
                          onChange={handleInputChange}
                          defaultValue=""
                        />
                      </div> */}


                      {/* <div className="col-md-4">
                        <label>
                          Number of Additional Guests (N)
                          <i className="fal fa-users" />
                        </label>
                        <input
                          type="number"
                          placeholder="Number of Additional Guests"
                          defaultValue=""
                          name="no_of_addi_guest"
                          value={formData.no_of_addi_guest}
                          onChange={handleInputChange}
                        />
                      </div> */}


                      <div className="col-md-4">
                        <label>
                          Cleaning Fee (N)
                          <i className="fal fa-broom" />
                        </label>
                        <input
                          type="number"
                          placeholder="Cleaning Fee"
                          name="clean_fee"
                          value={formData.clean_fee}
                          onChange={handleInputChange}
                          defaultValue=""
                        />
                      </div>
                      {/* <div className="col-md-8 d- flex justify-content-spacebetween">
                        <label style={{ paddingRight: "10px" }}>
                          Daily and Per Stay Fee
                        </label>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="clean_fee_daily"
                                    type="radio"
                                    name="clean_fee_daily"
                                    value="Daily"
                                    checked={formData.clean_fee_daily}
                                    onChange={handleInputChange2}
                                  />
                                  <label htmlFor="clean_fee_daily">Daily</label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="clean_fee_per_stay"
                                    type="radio"
                                    name="clean_fee_per_stay"
                                    value="Per Stay"
                                    checked={formData.clean_fee_per_stay}
                                    onChange={handleInputChange2}
                                  />
                                  <label htmlFor="clean_fee_per_stay">
                                    Per Stay
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-4">
                        <label>
                          City Fee (N)
                          <i className="fal fa-city" />
                        </label>
                        <input
                          type="number"
                          placeholder="City Fee"
                          defaultValue=""
                          name="city_fee"
                          value={formData.city_fee}
                          onChange={handleInputChange}
                        />
                      </div>


                      {/* <div className="col-md-8 d- flex justify-content-spacebetween">
                        <label style={{ paddingRight: "10px" }}>
                          Daily and Per Stay Fee
                        </label>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="city_fee_daily"
                                    type="radio"
                                    name="city_fee_daily"
                                    value="Daily"
                                    checked={formData.city_fee_daily}
                                    onChange={handleInputChange1}
                                  />
                                  <label htmlFor="city_fee_daily">Daily</label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="city_fee_per_stay"
                                    type="radio"
                                    name="city_fee_per_stay"
                                    value="Per Stay"
                                    checked={formData.city_fee_per_stay}
                                    onChange={handleInputChange1}
                                  />
                                  <label htmlFor="city_fee_per_stay">
                                    Per Stay
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="col-md-4">
                        <label>
                          Security Deposit (N)
                          <i className="fal fa-shield-alt" />
                        </label>
                        <input
                          name="security_deposit"
                          type="number"
                          placeholder="Security Deposit"
                          // defaultValue=""
                          value={formData.security_deposit}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Tax (N)
                          <i className="fal fa-percent" />
                        </label>
                        <input
                          type="number"
                          name="tax"
                          value={formData.tax}
                          onChange={handleInputChange}
                          placeholder="Tax"
                          defaultValue=""
                        />
                      </div>
{/* new add */}
                      <div className="col-md-4">
                        <label>
                          Deposit
                          <i className="fal fa-percent" />
                        </label>
                        <input
                          type="number"
                          name="deposit_price"
                          value={formData.deposit_price}
                          onChange={handleInputChange}
                          placeholder="Deposit"
                          defaultValue=""
                        />
                      </div>

{/* new add */}


                      <div className="col-md-4">
                        <label>
                          Discount
                          <i className="fal fa-percent" />
                        </label>
                        <input
                          type="number"
                          name="discount"
                          value={formData.discount}
                          onChange={handleInputChange}
                          placeholder="discount"
                          defaultValue=""
                        />
                      </div>










                    </div>
                    <div className="dashboard-title  dt-inbox fl-wrap">
                      <h3>Amenities</h3>
                    </div>
                    <div className="profile-edit-container fl-wrap block_box">
                      <div className="custom-form">
                        {/* Checkboxes */}
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          {amenity.map((item, index) => (
                            <li key={index}>
                              <input
                                id={`check-${index}`}
                              style={{position:'relative',zIndex:'99999'}}

                                type="checkbox"
                                name={item.name} // Use item.name for checkbox name
                                defaultChecked=""
                                onChange={valueGetAmenities}
                              />
                              <label htmlFor={`check-${index}`}>
                                {item.name}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="dashboard-title  dt-inbox fl-wrap">
                      <h3>Facilities</h3>
                    </div>
                    <div className="profile-edit-container fl-wrap block_box">
                      <div className="custom-form">
                        {/* Checkboxes */}
                        <ul className="fl-wrap filter-tags no-list-style ds-tg">
                          {facility.map((item, index) => (
                            <li key={index}>
                              <input
                              style={{position:'relative',zIndex:'99999'}}
                                id={`check-${index}`}
                                type="checkbox"
                                name={item.name}
                                defaultChecked=""
                                onChange={valueGetFacilities}
                              />
                              <label htmlFor={`check-${index}`}>
                                {item.name}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12" style={{zIndex:'99999' , position:'relative'}}>
                      <label>
                        Address
                        <i className="fal fa-map-marker-alt" />
                      </label>
                      <input
                        type="text"
                        // value={query}
                        

                        ref={autoCompleteRef}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="search places"
                        value={query}
                      />
                    </div>
                    <div className="col-md-12" style={{}}>
                      <GoogleMap
                        mapContainerStyle={{ height: "500px" }}
                        center={selectedLocation}
                        zoom={15}
                        onLoad={onMapLoad}
                      >
                        <MarkerF position={selectedLocation} />
                      </GoogleMap>
                    </div>

                    <div className="col-md-6">
                      <label>
                        Apartment/Suite
                        <i className="fal fa-building" />
                      </label>
                      <input
                        type="text"
                        name="apt_suite"
                        value={formData.apt_suite}
                        onChange={handleInputChange}
                        placeholder="Apartment/Suite"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-6">
                      <label>
                        City
                        <i className="fal fa-city" />
                      </label>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        placeholder="City"
                        onChange={handleInputChange}
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        State
                        <i className="fal fa-map" />
                      </label>
                      <input
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={handleInputChange}
                        placeholder="State"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        Zipcode
                        <i className="fal fa-mail-bulk" />
                      </label>
                      <input
                        type="text"
                        name="zipcode"
                        value={formData.zipcode}
                        onChange={handleInputChange}
                        placeholder="Zipcode"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        Area
                        <i className="fal fa-map-marked-alt" />
                      </label>
                      <input
                        type="text"
                        name="area"
                        value={formData.area}
                        onChange={handleInputChange}
                        placeholder="Area"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        Country
                        <i className="fal fa-globe" />
                      </label>
                      <input
                        type="text"
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                        placeholder="Country"
                        defaultValue=""
                      />
                    </div>

                    <div className="col-md-4">
                      <label>
                        Cancellation Policy
                        <i className="fal fa-times-circle" />
                      </label>
                      <div className="listsearch-input-item">
                        <select
                          value={formData.cancellation_policy}
                          name="cancellation_policy"
                          onChange={handleInputChange}
                          data-placeholder="Apartments"
                          className="nice-select chosen-select no-search-select"
                          // style={{ display: "none" }}
                        >
                          <option>cancellation policy</option>
                          <option>Shops</option>
                          <option>Hotels</option>
                          <option>Restaurants</option>
                          <option>Fitness</option>
                          <option>Events</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                      <label>
                        Minimum Day Booking
                        <i className="fal fa-calendar-minus" />
                      </label>
                      <input
                        type="text"
                        name="min_day_booking"
                        value={formData.min_day_booking}
                        onChange={handleInputChange}
                        placeholder="Minimum Day Booking"
                        defaultValue=""
                      />
                    </div> */}
                    {/* <div className="col-md-4" style={{ float: "right" }}>
                      <label>
                        Maximum Day Booking
                        <i className="fal fa-calendar-plus" />
                      </label>
                      <input
                        name="max_day_booking"
                        value={formData.max_day_booking}
                        onChange={handleInputChange}
                        type="text"
                        placeholder="Maximum Day Booking"
                        defaultValue=""
                      />
                    </div> */}
                    <div className="col-md-4">
                      <label>
                        Check-in After
                        <i className="fal fa-clock" />
                      </label>
                      <div className="listsearch-input-item">
                        <select
                          value={formData.check_in_after}
                          name="check_in_after"
                          onChange={handleInputChange}
                          data-placeholder="Apartments"
                          className="nice-select chosen-select no-search-select"
                          // style={{ display: "none" }}
                        >
                          <option>Select</option>
                          <option>8:00PM</option>
                          <option>8:30PM</option>
                          <option>9:00PM</option>
                          <option>9:30PM</option>
                          <option>10:00PM</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label>
                        Check-in Before
                        <i className="fal fa-clock" />
                      </label>
                      <div className="listsearch-input-item">
                        <select
                          value={formData.check_in_before}
                          name="check_in_before"
                          onChange={handleInputChange}
                          data-placeholder="Apartments"
                          className="nice-select chosen-select no-search-select"
                          // style={{ display: "none" }}
                        >
                          <option>Select</option>
                          <option>8:00PM</option>
                          <option>8:30PM</option>
                          <option>9:00PM</option>
                          <option>9:30PM</option>
                          <option>10:00PM</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12 d-flex justify-content-spacebetween">
                      <label style={{ paddingRight: "10px" }}>
                        Smoking Allowed
                      </label>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="smoking_allowed"
                                  type="radio"
                                  name="smoking_allowed"
                                  value="Yes"
                                  checked={formData.smoking_allowed === "Yes"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="smoking_allowed">Yes</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="smoking_allowed"
                                  type="radio"
                                  name="smoking_allowed"
                                  value="No"
                                  checked={formData.smoking_allowed === "No"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="smoking_allowed">No</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d- flex justify-content-spacebetween">
                      <label style={{ paddingRight: "10px" }}>
                        Pet Allowed
                      </label>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="pet_allowed"
                                  type="radio"
                                  name="pet_allowed"
                                  value="Yes"
                                  checked={formData.pet_allowed === "Yes"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="pet_allowed">Yes</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="pet_allowed"
                                  type="radio"
                                  name="pet_allowed"
                                  value="No"
                                  checked={formData.pet_allowed === "No"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="pet_allowed">No</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d- flex justify-content-spacebetween">
                      <label style={{ paddingRight: "10px" }}>
                        Party or Wedding Allowed
                      </label>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="party_allowed"
                                  type="radio"
                                  name="party_allowed"
                                  value="Yes"
                                  checked={formData.party_allowed === "Yes"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="party_allowed">Yes</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="party_allowed"
                                  type="radio"
                                  name="party_allowed"
                                  value="No"
                                  checked={formData.party_allowed === "No"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="party_allowed">No</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d- flex justify-content-spacebetween">
                      <label style={{ paddingRight: "10px" }}>
                        Children Allowed
                      </label>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="children_allowed"
                                  type="radio"
                                  name="children_allowed"
                                  value="True"
                                  checked={formData.children_allowed === "True"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="children_allowed">Yes</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="children_allowed"
                                  type="radio"
                                  name="children_allowed"
                                  value="false"
                                  checked={
                                    formData.children_allowed === "false"
                                  }
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="children_allowed">No</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Multiple Input Code Start */}
                    {bedrooms?.map((bedroom, index) => (
                      <div className="row" key={index}>
                        <div className="col-md-6">
                          <label>Name of Bedroom {index + 1}</label>
                          <input
                            type="text"
                            value={bedroom.name}
                            onChange={(e) =>
                              handleInputChangess(e.target.value, index, "name")
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Number of Guests</label>
                          <input
                            type="number"
                            value={bedroom.guest}
                            onChange={(e) =>
                              handleInputChangess(
                                e.target.value,
                                index,
                                "guest"
                              )
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Number of Beds</label>
                          <input
                            type="number"
                            value={bedroom.bed}
                            onChange={(e) =>
                              handleInputChangess(e.target.value, index, "bed")
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Number of Type</label>
                          <input
                            type="number"
                            value={bedroom.type}
                            onChange={(e) =>
                              handleInputChangess(e.target.value, index, "type")
                            }
                          />
                        </div>
                        {bedrooms.length > 1 && (
                          <button
                            className="btn btn-danger"
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              marginBottom: "20px",
                              marginLeft: "20px",
                            }}
                            onClick={() => handleMinusClick(index)}
                          >
                            Remove Bedroom
                          </button>
                        )}
                      </div>
                    ))}

                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginBottom: "30px",
                      }}
                    >
                      <div
                        className="logout_btn color2-bg cursor-pointer"
                        onClick={handlePlusClick}
                      >
                        Add New Bedroom
                        <i className="fas fa-sign-out" />
                      </div>
                    </div>
                    {/* Multiple Input Code End */}
                   

                    <div className="col-md-8">
                      <label>House Rules Additional Information</label>
                      <textarea
                        cols={40}
                        name="house_rule_addi_info"
                        rows={3}
                        onChange={handleInputChange}
                        placeholder="Information"
                        defaultValue={""}
                      />
                    </div>

                    <div className="col-md-4">
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        acceptType={ ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp', 'ico']}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          <div className="upload__image-wrapper">
                            <div
                              className="fu-text"
                              style={isDragging ? { color: "red" } : null}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <div className="add-list-media-wrap">
                                <div className="listsearch-input-item fl-wrap">
                                  <div className="fuzone">
                                    <form>
                                      <div className="fu-text">
                                        <span>
                                          <i className="fal fa-images" /> Click
                                          here or drop files to upload
                                        </span>
                                        <div className="photoUpload-files fl-wrap" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                              <div className="photoUpload-files fl-wrap" />
                            </div>
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "flex-start",
                              }}
                            >
                              {imageList?.map((image, index) => (
                                <div
                                  key={index}
                                  className="image-item"
                                  style={{ margin: "12px" }}
                                >
                                  <img
                                    src={image.data_url}
                                    alt=""
                                    style={{ width: "100px", height: "80px" }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>
                </div>
                {/*add_room-item end  */}
              </div>
              <button
                className="logout_btn color2-bg"
                type="submit"
                style={{ width: "120px" }}
              >
                {loaders ? (
                  <BtnLoader />
                ) : (
                  <>
                    Add <i className="fas fa-sign-out" />
                  </>
                )}
              </button>
            </div>
          </div>
          </form>  

      </>
       : 
       <img src={'/image/loading.webp'} alt=""/>
      } 
          {/* <div style={{
                    lineHeight:'36px'
                  }}>

                  <h1 style={{
                    color:'blue',
                    textAlign:'center',
                    lineHeight:'36px'
                  }}>
                  Dear User, please subscribe first to use this website.
                  </h1>
                  <p>
                  This website requires a monthly subscription of $5. If your payment is not received for any month, your listing will be disabled. Once you subscribe, your listing will be activated.
                  </p>
                  <button disabled={loader} onClick={()=>(SubscribeMonthlyDeal(),setLoader(true))} style={{
                    padding:'10px',
                    backgroundColor:'#6DBBFD',
                    color:'white',
                    cursor:'pointer',
                    width:'30%',
                    borderRadius:'5px',
                    border:'none'

                  }}>
          {loader ? 'Loading......' : "Subscribe" }

                    
                  </button>
                </div>} */}
                </>
      )}
    </>
  );
}
export default ListenRight;


const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";
  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => {
      callback();
    };
  }
  script.src = url;
  
  document.getElementsByTagName("head")[0].appendChild(script);
};